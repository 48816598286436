var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Добавить пользователя"},on:{"change":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"res-w-100",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){return cancel()}}},[_vm._v(" Отмена ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Добавить")])])]}}]),model:{value:(_vm.isAddNewUserModalActive),callback:function ($$v) {_vm.isAddNewUserModalActive=$$v},expression:"isAddNewUserModalActive"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Имя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Имя","label-for":"new-user-name"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-user-name","placeholder":"Имя"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Фамилия","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Фамилия","label-for":"new-user-lastname"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-user-lastname","placeholder":"Фамилия"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Номер","rules":"required|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Номер","label-for":"new-user-phone"}},[_c('masked-input',{staticClass:"form-control",attrs:{"id":"new-user-phone","state":errors.length > 0 ? false : null,"mask":"998111111111","placeholder":"Номер"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Почта","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Почта","label-for":"new-user-email"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-user-email","placeholder":"Почта"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Дата рождения","rules":"required"}},[_c('b-form-group',{attrs:{"label":"Дата рождения","label-for":"new-user-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.datePickerConfig,"placeholder":"Выберите дату","id":"new-user-date"},model:{value:(_vm.userDate),callback:function ($$v) {_vm.userDate=$$v},expression:"userDate"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Пароль","label-for":"new-user-password"}},[_c('validation-provider',{attrs:{"vid":"Password","name":"Пароль","rules":"required|custom-password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"new-user-password","type":_vm.password1FieldType,"state":errors.length > 0 ? false : null,"placeholder":"············"},on:{"input":function($event){_vm.passwordTextShow = true}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.passwordTextShow),expression:"passwordTextShow"}],staticClass:"text-secondary"},[_vm._v("Ваш пароль должен содержать по крайней мере один верхний регистр и одну цифру")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Подтвердите пароль","label-for":"new-user-confirm-password"}},[_c('validation-provider',{attrs:{"name":"Пароль","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"new-user-confirm-password","type":_vm.password2FieldType,"state":errors.length > 0 ? false : null,"placeholder":"············"},model:{value:(_vm.cPassword),callback:function ($$v) {_vm.cPassword=$$v},expression:"cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Роль","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Роль","label-for":"new-user-role"}},[_c('v-select',{attrs:{"id":"new-user-role","placeholder":"Выберите роль","state":errors.length > 0 ? false : null,"label":"text","options":_vm.rolesOptions},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }