<template>
  <b-modal
    id="modal-center"
    @change="hideModal"
    centered
    v-model="isAddNewUserModalActive"
    title="Добавить пользователя"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Имя"
              rules="required"
            >
              <b-form-group label="Имя" label-for="new-user-name">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="firstName"
                  id="new-user-name"
                  placeholder="Имя"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Фамилия"
              rules="required"
            >
              <b-form-group label="Фамилия" label-for="new-user-lastname">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="lastName"
                  id="new-user-lastname"
                  placeholder="Фамилия"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Номер"
              rules="required|min:9"
            >
              <b-form-group label="Номер" label-for="new-user-phone">
                <masked-input
                  v-model="phone"
                  class="form-control"
                  id="new-user-phone"
                  :state="errors.length > 0 ? false : null"
                  mask="998111111111"
                  placeholder="Номер"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

              <!--  <b-form-group label="Номер" label-for="new-user-phone">
                <cleave
                  id="new-user-phone"
                  v-model="phone"
                  type="tel"
                  class="form-control"
                  :state="errors.length > 0 ? false : null"
                  :raw="false"
                  :options="prefixOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group> -->
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Почта"
              rules="required|email"
            >
              <b-form-group label="Почта" label-for="new-user-email">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="userEmail"
                  id="new-user-email"
                  placeholder="Почта"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <validation-provider name="Дата рождения" rules="required">
              <b-form-group label="Дата рождения" label-for="new-user-date">
                <flat-pickr
                  v-model="userDate"
                  :config="datePickerConfig"
                  placeholder="Выберите дату"
                  id="new-user-date"
                  class="form-control"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12">
            <b-form-group label="Пароль" label-for="new-user-password">
              <validation-provider
                #default="{ errors }"
                vid="Password"
                name="Пароль"
                rules="required|custom-password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="new-user-password"
                    @input="passwordTextShow = true"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-secondary" v-show="passwordTextShow"
                  >Ваш пароль должен содержать по крайней мере один верхний
                  регистр и одну цифру</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12">
            <b-form-group
              label="Подтвердите пароль"
              label-for="new-user-confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Пароль"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="new-user-confirm-password"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Роль"
              rules="required"
            >
              <b-form-group label="Роль" label-for="new-user-role">
                <v-select
                  id="new-user-role"
                  placeholder="Выберите роль"
                  v-model="selectedRole"
                  :state="errors.length > 0 ? false : null"
                  label="text"
                  :options="rolesOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        type="reset"
        class="res-w-100"
        @click="cancel()"
      >
        Отмена
      </b-button>
      <b-button
        class="res-mt-2 res-w-100"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click.prevent="handleSubmit"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MaskedInput from "vue-masked-input";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    flatPickr,
    BFormRadioGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    MaskedInput,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewUserModalActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    userType() {
      if (this.selectedRole.value == "client") {
        return "v1/user/create";
      } else if (this.selectedRole.value == "admin") {
        return "v1/user/create-admin";
      }
    },
  },
  data() {
    return {
      required,
      email,
      password,
      confirmed,

      prefixOptions: {
        prefix: "998",
        blocks: [12],
      },

      cPassword: "",
      password: "",
      userEmail: "",
      passwordTextShow: false,
      password1FieldType: "password",
      password2FieldType: "password",
      datePickerConfig: {
        altInput: true,
        altFormat: "j F, Y",
        dateFormat: "U",
        locale: Russian,
      },
      firstName: null,
      lastName: null,
      phone: null,
      userDate: null,
      selectedRole: {
        text: "Клиент",
        value: "client",
      },
      rolesOptions: [
        { text: "Клиент", value: "client" },
        { text: "Админ", value: "admin" },
      ],
    };
  },
  mounted() {
    /* For flatPickr year typing works */
    this.$root.$on("bv::modal::shown", (bvEvent) => {
      bvEvent.vueTarget.$refs.content.attributes.removeNamedItem("tabindex");
    });
  },
  methods: {
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const numericDate = Number(this.userDate);
          axiosIns
            .post(this.userType, {
              firstname: this.firstName,
              lastname: this.lastName,
              login: this.phone,
              email: this.userEmail,
              password: this.password,
              passwordConfirm: this.cPassword,
              birthDate: numericDate,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Пользователь был успешно создан!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.hideModal();
              this.$emit("refetch-data");
              setTimeout(() => {
                this.resetForm();
              }, 200);
            });
        }
      });
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    hideModal() {
      this.$emit("hideModal");
    },
    resetForm() {
      this.firstName = null;
      this.lastName = null;
      this.phone = null;
      this.userEmail = null;
      this.password = null;
      this.cPassword = null;
      this.userDate = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
